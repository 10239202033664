<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :disable-delete-button="true"
            :disable-save-button="true"
            @created="created($event)"
            @updated="updated($event)"
            @cancelled="cancelled"
            @deleted="deleted"
            @valid="baseFormValid($event)"
        >
            <v-row v-if="form.deliveryJob" class="pt-2 grey-bg">
                <v-col cols="12" sm="8" class="py-0">
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row class="mb-6">
                                <entity-select-input
                                    v-model="form.deliveryJob.vehicleType"
                                    v-validate.immediate="'required'"
                                    :label="$t('orders.delivery_vehicle_type')"
                                    :data-vv-as="
                                        $t('orders.delivery_vehicle_type')
                                    "
                                    :entity="selectApiClientType.VEHICLE_TYPE"
                                    name="vehicleType"
                                    :error-messages="
                                        errors.collect('vehicleType')
                                    "
                                    autocomplete
                                    translate-items
                                ></entity-select-input>
                            </form-one-col-row>
                            <form-one-col-row>
                                <entity-select-input
                                    v-model="
                                        form.deliveryJob.primaryDeliveryMethod
                                    "
                                    v-validate="{}"
                                    v-tab-error:general
                                    label="labels.primary_delivery_method"
                                    :data-vv-as="
                                        $t('labels.primary_delivery_method')
                                    "
                                    :entity="
                                        selectApiClientType.DELIVERY_METHOD
                                    "
                                    :extra-options="
                                        deliveryMethodsFromDeliveryRule
                                    "
                                    :items-filter="overrideProviderListItems"
                                    name="primaryDeliveryMethod"
                                    autocomplete
                                    :error-messages="
                                        errors.collect(
                                            'primary_delivery_method'
                                        )
                                    "
                                    translate-items
                                ></entity-select-input>
                            </form-one-col-row>
                            <form-one-col-row class="mb-2">
                                <checkbox-input
                                    v-model="form.disableAutoCancel"
                                    label="orders.disable_auto_cancel"
                                    name="disableAutoCancel"
                                ></checkbox-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>

                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row>
                                <template>
                                    <date-picker-input
                                        v-model="form.deliveryJob.pickupTime"
                                        v-validate.immediate="
                                            'required|dateafter'
                                        "
                                        :label="$t('orders.pickup_date')"
                                        name="pickupTime"
                                        :error-messages="
                                            errors.collect('pickupTime')
                                        "
                                        :data-vv-as="$t('labels.pickup_date')"
                                        :min="minDate"
                                    ></date-picker-input>
                                </template>
                            </form-one-col-row>

                            <form-one-col-row>
                                <address-autocomplete-input
                                    v-model="
                                        form.deliveryJob.pickupAddress
                                            .full_address
                                    "
                                    v-validate.immediate="'required|max:255'"
                                    :latitude.sync="
                                        form.deliveryJob.pickupAddress.latitude
                                    "
                                    :longitude.sync="
                                        form.deliveryJob.pickupAddress.longitude
                                    "
                                    :country.sync="
                                        form.deliveryJob.pickupAddress.country
                                    "
                                    :town.sync="
                                        form.deliveryJob.pickupAddress.city
                                    "
                                    :zip-code.sync="
                                        form.deliveryJob.pickupAddress
                                            .postal_code
                                    "
                                    :street.sync="
                                        form.deliveryJob.pickupAddress.street
                                    "
                                    :house-number.sync="
                                        form.deliveryJob.pickupAddress
                                            .street_number
                                    "
                                    :error-messages="
                                        errors.collect('pickupAddress')
                                    "
                                    :label="$t('orders.pickup_address')"
                                    name="pickupAddress"
                                    :data-vv-as="$t('orders.pickup_address')"
                                ></address-autocomplete-input>
                            </form-one-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_first_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="pickupFirstName"
                                        :error-messages="
                                            errors.collect('pickupFirstName')
                                        "
                                        :data-vv-as="$t('orders.first_name')"
                                        :label="$t('orders.first_name')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_last_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        :label="$t('orders.last_name')"
                                        name="pickupLastName"
                                        :error-messages="
                                            errors.collect('pickupLastName')
                                        "
                                        :data-vv-as="$t('orders.last_name')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_phone
                                        "
                                        v-validate="'required|phonenumber'"
                                        name="pickupPhoneNumber"
                                        :label="$t('orders.phone_number')"
                                        :error-messages="
                                            errors.collect('pickupPhoneNumber')
                                        "
                                        :data-vv-as="$t('orders.phone_number')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_company
                                        "
                                        :label="$t('orders.pickup_company')"
                                        name="pickupCompany"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.deliveryJob.pickupComment"
                                    name="pickupComment"
                                    :label="$t('orders.pickup_comment')"
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.deliveryJob.packageInfo"
                                    name="packageInfo"
                                    :label="$t('orders.package_info')"
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                    <div></div>
                    <v-card elevation="0">
                        <v-card-text>
                            <form-one-col-row>
                                <address-autocomplete-input
                                    v-model="
                                        form.deliveryJob.dropoffAddress
                                            .full_address
                                    "
                                    v-validate.immediate="'required|max:255'"
                                    :label="$t('orders.dropoff_address')"
                                    :latitude.sync="
                                        form.deliveryJob.dropoffAddress.latitude
                                    "
                                    :longitude.sync="
                                        form.deliveryJob.dropoffAddress
                                            .longitude
                                    "
                                    :country.sync="
                                        form.deliveryJob.dropoffAddress.country
                                    "
                                    :town.sync="
                                        form.deliveryJob.dropoffAddress.city
                                    "
                                    :zip-code.sync="
                                        form.deliveryJob.dropoffAddress
                                            .postal_code
                                    "
                                    :street.sync="
                                        form.deliveryJob.dropoffAddress.street
                                    "
                                    :house-number.sync="
                                        form.deliveryJob.dropoffAddress
                                            .street_number
                                    "
                                    name="dropoffAddress"
                                    :error-messages="
                                        errors.collect('dropoffAddress')
                                    "
                                    :data-vv-as="$t('orders.dropoff_address')"
                                ></address-autocomplete-input>
                            </form-one-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_first_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="dropoffFirstName"
                                        :error-messages="
                                            errors.collect('dropoffFirstName')
                                        "
                                        :data-vv-as="$t('orders.first_name')"
                                        :label="$t('orders.first_name')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_last_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        :label="$t('orders.last_name')"
                                        :error-messages="
                                            errors.collect('dropoffLastName')
                                        "
                                        :data-vv-as="$t('orders.last_name')"
                                        name="dropoffLastName"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_phone
                                        "
                                        v-validate="'required|phonenumber'"
                                        name="dropoffPhoneNumber"
                                        :error-messages="
                                            errors.collect('dropoffPhoneNumber')
                                        "
                                        :data-vv-as="$t('orders.phone_number')"
                                        :label="$t('orders.phone_number')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_company
                                        "
                                        :label="$t('orders.company')"
                                        name="dropoffCompany"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.deliveryJob.dropOffComment"
                                    name="dropoffComment"
                                    :label="$t('orders.dropoff_comment')"
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                    <address-multiple-map-input
                        v-model="form.deliveryJob.pickupAddress.full_address"
                        v-tab-error:general
                        style="height: 200px"
                        name="deliveryFullAddressMap"
                        :addresses="markers"
                        :draggable="false"
                    ></address-multiple-map-input>

                    <v-container>
                        <v-row justify="space-around">
                            <v-card width="400" elevation="0">
                                <v-card-text>
                                    <timeline />
                                </v-card-text>
                            </v-card>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </base-form>
        <portal to="footer">
            <v-btn
                color="grey"
                large
                outlined
                style="margin-right: 0.75rem"
                @click="backtolist"
            >
                {{ $t('actions.back-to-list') }}
            </v-btn>

            <v-btn
                color="success"
                large
                :disabled="!isValid"
                @click="createRequestNewDelivery"
            >
                <v-icon left> content_copy </v-icon>
                {{ $t('actions.create-request-new-delivery') }}
            </v-btn>
        </portal>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import Notification from '@/services/Notification/Notification'
import configType from '@/store/type/configType'
import Timeline from './Timeline'
import dirtyType from '@/store/type/dirtyType'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'
import cloneDeep from 'lodash/cloneDeep'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMultipleMapInput from '@/components/form/Inputs/google/AddressMultipleMapInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import filter from 'lodash/filter'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'

export default {
    components: {
        AddressAutocompleteInput,
        AddressMultipleMapInput,
        EntitySelectInput,
        Timeline,
        CheckboxInput,
    },
    mixins: [FormExtend, AuthMixin],
    data() {
        return {
            api: ApiClientType.DELIVERIES,
            mapper: MapperType.DELIVERY_FORM,
            form: {},
            entity: 'labels.deliveries',
            editRoute: routeType.DELIVERY_EDIT,
            listRoute: routeType.DELIVERY_LIST,
            orderId: null,
            deliverProviderJobCount: null,
            baseFormLoaded: false,
            isValid: false,
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        minDate() {
            return this.$date().format('YYYY-MM-DD')
        },
        title: function () {
            return this.$t('labels.deliveries')
        },
        ...mapState({
            deliveryDialog: (state) => state.dirty.deliveryDialog,
        }),
        getStatus() {
            return Object.keys(this.form)
        },
        markers() {
            return [
                {
                    address: this.form.deliveryJob.pickupAddress.full_address,
                    icon: 'restaurant',
                    lat: this.form.deliveryJob.pickupAddress.latitude,
                    lng: this.form.deliveryJob.pickupAddress.longitude,
                },
                {
                    address: this.form.deliveryJob.dropoffAddress.full_address,
                    icon: 'dropoff',
                    lat: this.form.deliveryJob.dropoffAddress.latitude,
                    lng: this.form.deliveryJob.dropoffAddress.longitude,
                },
            ]
        },
        deliveryMethodsFromDeliveryRule() {
            let extraOptions = []
            if (
                this.form.deliveryJob?.deliveryMethodsFromDeliveryRule &&
                this.form.deliveryJob?.deliveryMethodsFromDeliveryRule.length
            ) {
                extraOptions.push({
                    id: 'ALL',
                    key: this.form.deliveryJob.deliveryMethodsFromDeliveryRule.join(
                        ','
                    ),
                    label: `${this.$t(
                        'enums.delivery_methods.all'
                    )} (${this.form.deliveryJob.deliveryMethodsFromDeliveryRule.join(
                        ','
                    )})`,
                    name: 'all',
                })
            }
            return extraOptions
        },
    },
    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
        ...mapMutations({
            setCancelDeliveryDialog:
                dirtyType.mutations.SET_CANCELDELIVERYDIALOG,
        }),
        editRedirectParams: (data) => ({ id: data.id }),
        cancelDeliveryDialog() {
            this.setCancelDeliveryDialog(false)
        },
        getDeliveryJobData(deliveryJobId) {
            RestApiCollection.get(this.api)
                .getDeliveryJob(deliveryJobId)
                .then((data) => {
                    this.form = data
                })
                .catch(() => {
                    //this.$emit('failed', this.orderId)
                })
        },
        async createRequestNewDelivery() {
            this.$refs.form.busy = true
            const entity = MapperCollection.get(this.mapper).toEntity(
                this.$refs.form.value
            )

            this.$refs.form.resetFormErrors()
            this.$refs.form.hasFormErrors = false

            if (this.$refs.form.entityId) {
                try {
                    // Update delivery job
                    await RestApiCollection.get(this.api).updateDeliveryJob(
                        entity.deliveryJob
                    )
                    this.$refs.form.savedFormdata = cloneDeep(entity)
                    this.$refs.form.setDirty(false)

                    // Create delivery provider job
                    let providerJobData
                    if (
                        entity.deliveryJob.primaryDeliveryMethod.indexOf(',') >
                        -1
                    ) {
                        for (const method of entity.deliveryJob.primaryDeliveryMethod.split(
                            ','
                        )) {
                            providerJobData = {
                                method: method,
                                disableAutoCancel: entity.disableAutoCancel,
                            }
                            await RestApiCollection.get(
                                this.api
                            ).createAndQueueDeliveryProviderJob(
                                providerJobData,
                                entity.deliveryJob.id
                            )
                        }
                    } else {
                        providerJobData = {
                            method: entity.deliveryJob.primaryDeliveryMethod,
                            disableAutoCancel: entity.disableAutoCancel,
                        }
                        await RestApiCollection.get(
                            this.api
                        ).createAndQueueDeliveryProviderJob(
                            providerJobData,
                            entity.deliveryJob.id
                        )
                    }

                    const orderIdentifier = entity.deliveryJob.orderIdentifiers[
                        Object.keys(entity.deliveryJob.orderIdentifiers)[0]
                    ].toString()

                    this.$refs.form.busy = false
                    this.$route.query.redirectTo = {
                        name: this.$route.params?.backTo
                            ? this.$route.params.backTo.name
                            : routeType.DELIVERY_LIST,
                        params: this.$route.params?.backTo
                            ? this.$route.params.backTo.params
                            : {},
                        query: this.$route.params?.backTo
                            ? this.$route.params.backTo.query
                            : {
                                  'deliveryJob.orders.identifier': orderIdentifier,
                              },
                    }

                    Notification.entityCreated(
                        orderIdentifier,
                        'Delivery provider job'
                    )
                    this.$router.push(this.$route.query.redirectTo)
                } catch (reason) {
                    if (reason.response.status === 404) {
                        this.$router.push({ name: 'not-found' })
                        return
                    }

                    if (reason.response.status !== 400) {
                        this.$refs.form.busy = false
                        return
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            reason.response.data,
                            'violations'
                        )
                    ) {
                        reason.response.data.violations.forEach((violation) => {
                            if (
                                this.$validator.fields.find({
                                    name: violation.propertyPath,
                                })
                            ) {
                                this.errors.add({
                                    field: violation.propertyPath,
                                    msg: violation.message,
                                })
                            } else {
                                this.$refs.form.hasFormErrors = true
                                this.$refs.form.formErrors.push(
                                    violation.propertyPath +
                                        ': ' +
                                        violation.message
                                )
                            }
                        })
                    } else {
                        this.$refs.form.hasFormErrors = true
                        this.$refs.form.formErrors.push(
                            Object.prototype.hasOwnProperty.call(
                                reason.response.data,
                                'message'
                            )
                                ? reason.response.data.message
                                : 'Server error'
                        )
                    }

                    Notification.formError()
                    this.$refs.form.busy = false
                }
            }
        },
        backtolist() {
            this.$router.back()
        },
        overrideProviderListItems(item) {
            return filter(item, function (o) {
                return o.key != 'restaurant_managed'
            })
        },
    },
}
</script>
