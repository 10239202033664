<template>
    <div>
        <delivery-form></delivery-form>
    </div>
</template>

<script>
import deliveryForm from '@/components/pages/deliveries/CreateForm'
import dirtyForm from '@/components/mixins/dirtyForm'

export default {
    components: {
        deliveryForm,
    },

    mixins: [dirtyForm],
}
</script>
