<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :title="title"
            :disabled="disabled"
            :disable-delete-button="true"
            @created="created($event)"
            @updated="updated($event)"
            @valid="baseFormValid($event)"
        >
            <v-row class="pt-2 grey-bg">
                <v-col cols="12" sm="8" class="py-0">
                    <v-alert
                        v-if="form.error != null"
                        color="red"
                        dense
                        text
                        type="error"
                        >Error: {{ form.error }}
                    </v-alert>
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row>
                                <entity-select-input
                                    v-model="form.vehicleType"
                                    v-validate.immediate="'required'"
                                    :label="$t('orders.delivery_vehicle_type')"
                                    :data-vv-as="
                                        $t('orders.delivery_vehicle_type')
                                    "
                                    :entity="selectApiClientType.VEHICLE_TYPE"
                                    name="vehicleType"
                                    autocomplete
                                    :error-messages="
                                        errors.collect('vehicleType')
                                    "
                                    translate-items
                                ></entity-select-input>
                            </form-one-col-row>
                            <form-one-col-row>
                                <entity-select-input
                                    v-model="form.primaryDeliveryMethod"
                                    v-validate="{}"
                                    label="labels.primary_delivery_method"
                                    :data-vv-as="
                                        $t('labels.primary_delivery_method')
                                    "
                                    :entity="
                                        selectApiClientType.DELIVERY_METHOD
                                    "
                                    :items-filter="overrideProviderListItems"
                                    name="primaryDeliveryMethod"
                                    autocomplete
                                    :error-messages="
                                        errors.collect('primaryDeliveryMethod')
                                    "
                                    translate-items
                                ></entity-select-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row>
                                <template>
                                    <date-picker-input
                                        v-model="form.pickupTime"
                                        v-validate.immediate="
                                            'required|dateafter'
                                        "
                                        :label="$t('orders.pickup_date')"
                                        name="pickupTime"
                                        :error-messages="
                                            errors.collect('pickupTime')
                                        "
                                        :data-vv-as="$t('labels.pickup_date')"
                                        :min="minDate"
                                    ></date-picker-input>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <address-autocomplete-input
                                    v-model="form.pickupAddress.full_address"
                                    v-validate.immediate="'required|max:255'"
                                    :latitude.sync="form.pickupAddress.latitude"
                                    :longitude.sync="
                                        form.pickupAddress.longitude
                                    "
                                    :country.sync="form.pickupAddress.country"
                                    :town.sync="form.pickupAddress.city"
                                    :zip-code.sync="
                                        form.pickupAddress.postal_code
                                    "
                                    :street.sync="form.pickupAddress.street"
                                    :house-number.sync="
                                        form.pickupAddress.street_number
                                    "
                                    :error-messages="
                                        errors.collect('pickupAddress')
                                    "
                                    :label="$t('orders.pickup_address')"
                                    name="pickupAddress"
                                    :data-vv-as="$t('orders.pickup_address')"
                                ></address-autocomplete-input>
                            </form-one-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.pickupAddress
                                                .contact_first_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="pickupFirstName"
                                        :error-messages="
                                            errors.collect('pickupFirstName')
                                        "
                                        :data-vv-as="$t('orders.first_name')"
                                        :label="$t('orders.first_name')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.pickupAddress.contact_last_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        :label="$t('orders.last_name')"
                                        name="pickupLastName"
                                        :error-messages="
                                            errors.collect('pickupLastName')
                                        "
                                        :data-vv-as="$t('orders.last_name')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.pickupAddress.contact_phone
                                        "
                                        v-validate="'required|phonenumber'"
                                        name="pickupPhoneNumber"
                                        :label="$t('orders.phone_number')"
                                        :error-messages="
                                            errors.collect('pickupPhoneNumber')
                                        "
                                        :data-vv-as="$t('orders.phone_number')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.pickupAddress.contact_company
                                        "
                                        :label="$t('orders.pickup_company')"
                                        name="pickupCompany"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.pickupComment"
                                    name="pickupComment"
                                    :label="$t('orders.pickup_comment')"
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.packageInfo"
                                    name="packageInfo"
                                    :label="$t('orders.package_info')"
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                    <div></div>
                    <v-card elevation="0">
                        <v-card-text>
                            <form-one-col-row>
                                <address-autocomplete-input
                                    v-model="form.dropoffAddress.full_address"
                                    v-validate.immediate="'required|max:255'"
                                    :latitude.sync="
                                        form.dropoffAddress.latitude
                                    "
                                    :longitude.sync="
                                        form.dropoffAddress.longitude
                                    "
                                    :country.sync="form.dropoffAddress.country"
                                    :town.sync="form.dropoffAddress.city"
                                    :zip-code.sync="
                                        form.dropoffAddress.postal_code
                                    "
                                    :street.sync="form.dropoffAddress.street"
                                    :house-number.sync="
                                        form.dropoffAddress.street_number
                                    "
                                    :label="$t('orders.dropoff_address')"
                                    name="dropoffAddress"
                                    :error-messages="
                                        errors.collect('dropoffAddress')
                                    "
                                    :data-vv-as="$t('orders.dropoff_address')"
                                ></address-autocomplete-input>
                            </form-one-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.dropoffAddress
                                                .contact_first_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="dropoffFirstName"
                                        :error-messages="
                                            errors.collect('dropoffFirstName')
                                        "
                                        :data-vv-as="$t('orders.first_name')"
                                        :label="$t('orders.first_name')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.dropoffAddress
                                                .contact_last_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        :label="$t('orders.last_name')"
                                        :error-messages="
                                            errors.collect('dropoffLastName')
                                        "
                                        :data-vv-as="$t('orders.last_name')"
                                        name="dropoffLastName"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.dropoffAddress.contact_phone
                                        "
                                        v-validate="'required|phonenumber'"
                                        name="dropoffPhoneNumber"
                                        :error-messages="
                                            errors.collect('dropoffPhoneNumber')
                                        "
                                        :data-vv-as="$t('orders.phone_number')"
                                        :label="$t('orders.phone_number')"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.dropoffAddress.contact_company
                                        "
                                        :label="$t('orders.company')"
                                        name="dropoffCompany"
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.dropOffComment"
                                    name="dropoffComment"
                                    :label="$t('orders.dropoff_comment')"
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                    <address-multiple-map-input
                        v-model="form.pickupAddress.full_address"
                        style="height: 200px"
                        name="deliveryFullAddressMap"
                        :addresses="markers"
                        :draggable="false"
                    ></address-multiple-map-input>
                </v-col>
            </v-row>

            <portal to="footer">
                <v-btn
                    color="grey"
                    outlined
                    style="margin-right: 0.75rem"
                    @click="backtolist"
                >
                    {{ $t('actions.back-to-list') }}
                </v-btn>
            </portal>
        </base-form>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import configType from '@/store/type/configType'
import dirtyType from '@/store/type/dirtyType'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'
import cloneDeep from 'lodash/cloneDeep'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMultipleMapInput from '@/components/form/Inputs/google/AddressMultipleMapInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import IdFromIri from '@/services/IdFromIri'
import filter from 'lodash/filter'

export default {
    components: {
        AddressAutocompleteInput,
        AddressMultipleMapInput,
        EntitySelectInput,
    },
    mixins: [FormExtend, AuthMixin],
    data() {
        return {
            api: ApiClientType.DELIVERIES,
            mapper: MapperType.DELIVERY_FORM,
            form: {
                deliveryJob: {
                    pickupAddress: {
                        contact_company: null,
                        full_address: null,
                    },
                    dropoffAddress: {
                        contact_company: '',
                        full_address: null,
                    },
                },
            },
            entity: 'labels.deliveries',
            editRoute: routeType.DELIVERY_EDIT,
            listRoute: routeType.DELIVERY_LIST,
            orderId: null,
            deliverProviderJobCount: null,
            deliverJobId: null,
            isValid: false,
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        minDate() {
            return this.$date().format('YYYY-MM-DD')
        },
        title: function () {
            return this.$t('labels.deliveries')
        },
        ...mapState({
            deliveryDialog: (state) => state.dirty.deliveryDialog,
        }),
        getStatus() {
            return Object.keys(this.form)
        },
        markers() {
            let markers = [
                {
                    address: this.form.pickupAddress.full_address,
                    icon: 'restaurant',
                    lat: this.form.pickupAddress.latitude,
                    lng: this.form.pickupAddress.longitude,
                },
                {
                    address: this.form.dropoffAddress.full_address,
                    icon: 'dropoff',
                    lat: this.form.dropoffAddress.latitude,
                    lng: this.form.dropoffAddress.longitude,
                },
            ]
            return markers
        },
    },
    mounted() {
        this.getDeliveryDefaultData()
    },
    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
        ...mapMutations({
            setCancelDeliveryDialog:
                dirtyType.mutations.SET_CANCELDELIVERYDIALOG,
        }),
        editRedirectParams: (data) => ({
            id: IdFromIri.getId(data.deliveryProviderJobs[0]),
        }),
        countOrderProviderJobs(deliveryJobId) {
            RestApiCollection.get(this.api)
                .getDeliveryJob(deliveryJobId)
                .then((data) => {
                    this.deliverProviderJobCount =
                        data.deliveryProviderJobs.length
                })
                .catch(() => {
                    //this.$emit('failed', this.orderId)
                })
        },
        getDeliveryDefaultData() {
            //this.$refs.form.busy = true

            RestApiCollection.get(ApiClientType.ORDERS)
                .getDeliveryJobDefaultValues(this.$route.params.id)
                .then((data) => {
                    const entity = MapperCollection.get(this.mapper).fromEntity(
                        data
                    )
                    this.form = cloneDeep(entity)
                    this.$emit('input', entity)
                })
                .catch((reason) => {
                    if (reason.response.status === 404) {
                        this.$router.push({ name: 'not-found' })

                        return
                    }

                    this.$refs.form.hasFormErrors = true
                    this.$refs.form.formErrors.push(
                        Object.prototype.hasOwnProperty.call(
                            reason.response.data,
                            'message'
                        )
                            ? reason.response.data.message
                            : 'Server error'
                    )
                })
                .finally(() => {
                    //this.$refs.form.busy = false
                })
        },
        backtolist() {
            this.$router.back()
        },
        overrideProviderListItems(item) {
            return filter(item, function (o) {
                return o.key != 'restaurant_managed'
            })
        },
    },
}
</script>
