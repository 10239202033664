<template>
    <div>
        <base-form
            ref="form"
            v-model="form"
            :api="api"
            :mapper="mapper"
            :entity-id="entityId"
            :title="title"
            :disabled="disabled"
            :disable-save-button="true"
            :disable-delete-button="true"
            @valid="baseFormValid($event)"
        >
            <v-row v-if="form.deliveryJob" class="pt-2">
                <v-col cols="12" sm="8">
                    <v-alert
                        v-if="form.error != null"
                        color="red"
                        dense
                        text
                        type="error"
                        >Error: {{ form.error }}
                    </v-alert>
                    <v-card elevation="0" class="mb-4">
                        <v-card-text>
                            <form-one-col-row>
                                <template>
                                    <date-picker-input
                                        v-model="form.deliveryJob.pickupTime"
                                        v-validate.immediate="
                                            'required|dateafter'
                                        "
                                        label="orders.pickup_date"
                                        name="pickupDate"
                                        :error-messages="
                                            errors.collect('pickupDate')
                                        "
                                        :data-vv-as="$t('orders.pickup_date')"
                                        :editable="
                                            form.deliveryJob.canYouEditAddress
                                        "
                                        :min="minDate"
                                    ></date-picker-input>
                                </template>
                            </form-one-col-row>
                            <form-one-col-row>
                                <address-autocomplete-input
                                    v-model="
                                        form.deliveryJob.pickupAddress
                                            .full_address
                                    "
                                    v-validate.immediate="'required'"
                                    v-tab-error:contractual
                                    label="orders.pickup_address"
                                    :latitude.sync="
                                        form.deliveryJob.pickupAddress.latitude
                                    "
                                    :longitude.sync="
                                        form.deliveryJob.pickupAddress.longitude
                                    "
                                    :country.sync="
                                        form.deliveryJob.pickupAddress.country
                                    "
                                    :town.sync="
                                        form.deliveryJob.pickupAddress.city
                                    "
                                    :zip-code.sync="
                                        form.deliveryJob.pickupAddress
                                            .postal_code
                                    "
                                    :street.sync="
                                        form.deliveryJob.pickupAddress.street
                                    "
                                    :house-number.sync="
                                        form.deliveryJob.pickupAddress
                                            .street_number
                                    "
                                    name="pickupAddress"
                                    :data-vv-as="$t('orders.pickup_address')"
                                    :disabled="
                                        form.deliveryJob.canYouEditAddress
                                            ? false
                                            : true
                                    "
                                    :error-messages="
                                        errors.collect('pickupAddress')
                                    "
                                ></address-autocomplete-input>
                            </form-one-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_first_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="pickupFirstName"
                                        :label="$t('orders.first_name')"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                        :error-messages="
                                            errors.collect('pickupFirstName')
                                        "
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_last_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        :label="$t('orders.last_name')"
                                        name="pickupLastName"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                        :error-messages="
                                            errors.collect('pickupLastName')
                                        "
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_phone
                                        "
                                        v-validate="'required|phonenumber'"
                                        name="pickupPhoneNumber"
                                        :label="$t('orders.phone_number')"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        :error-messages="
                                            errors.collect('pickupPhoneNumber')
                                        "
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.pickupAddress
                                                .contact_company
                                        "
                                        :label="$t('orders.pickup_address')"
                                        name="pickupCompany"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.deliveryJob.pickupComment"
                                    name="pickupComment"
                                    :label="$t('orders.pickup_comment')"
                                    :disabled="
                                        form.deliveryJob.canYouEditContactInfo
                                            ? false
                                            : true
                                    "
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.deliveryJob.packageInfo"
                                    name="packageInfo"
                                    :label="$t('orders.package_info')"
                                    autocomplete="disabled"
                                    :disabled="
                                        form.deliveryJob.canYouEditContactInfo
                                            ? false
                                            : true
                                    "
                                ></text-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                    <div></div>
                    <v-card elevation="0">
                        <v-card-text>
                            <form-one-col-row>
                                <address-autocomplete-input
                                    v-model="
                                        form.deliveryJob.dropoffAddress
                                            .full_address
                                    "
                                    v-validate.immediate="'required'"
                                    v-tab-error:contractual
                                    :label="$t('orders.dropoff_address')"
                                    :latitude.sync="
                                        form.deliveryJob.dropoffAddress.latitude
                                    "
                                    :longitude.sync="
                                        form.deliveryJob.dropoffAddress
                                            .longitude
                                    "
                                    :country.sync="
                                        form.deliveryJob.dropoffAddress.country
                                    "
                                    :town.sync="
                                        form.deliveryJob.dropoffAddress.city
                                    "
                                    :zip-code.sync="
                                        form.deliveryJob.dropoffAddress
                                            .postal_code
                                    "
                                    :street.sync="
                                        form.deliveryJob.dropoffAddress.street
                                    "
                                    :house-number.sync="
                                        form.deliveryJob.dropoffAddress
                                            .street_number
                                    "
                                    name="dropoffAddress"
                                    :error-messages="
                                        errors.collect('dropoffAddress')
                                    "
                                    :data-vv-as="$t('labels.dropoff_address')"
                                    :disabled="
                                        form.deliveryJob.canYouEditAddress
                                            ? false
                                            : true
                                    "
                                ></address-autocomplete-input>
                            </form-one-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_first_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        name="dropoffFirstName"
                                        :label="$t('orders.first_name')"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                        :error-messages="
                                            errors.collect('dropoffFirstName')
                                        "
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_last_name
                                        "
                                        v-validate="
                                            'required|min:2|max:50|space'
                                        "
                                        :label="$t('orders.last_name')"
                                        name="dropoffLastName"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                        :error-messages="
                                            errors.collect('dropoffLastName')
                                        "
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-two-col-row>
                                <template v-slot:col1>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_phone
                                        "
                                        v-validate="'required|phonenumber'"
                                        name="dropoffPhoneNumber"
                                        :label="$t('orders.phone_number')"
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                        :error-messages="
                                            errors.collect('dropoffPhoneNumber')
                                        "
                                    ></text-input>
                                </template>
                                <template v-slot:col2>
                                    <text-input
                                        v-model="
                                            form.deliveryJob.dropoffAddress
                                                .contact_company
                                        "
                                        :label="$t('orders.company')"
                                        name="dropoffCompany"
                                        :error-messages="
                                            errors.collect(
                                                'dropoffAddress[contact_company]'
                                            )
                                        "
                                        :disabled="
                                            form.deliveryJob
                                                .canYouEditContactInfo
                                                ? false
                                                : true
                                        "
                                        autocomplete="disabled"
                                    ></text-input>
                                </template>
                            </form-two-col-row>
                            <form-one-col-row>
                                <text-input
                                    v-model="form.deliveryJob.dropOffComment"
                                    name="dropoffComment"
                                    :label="$t('orders.dropoff_comment')"
                                    :disabled="
                                        form.deliveryJob.canYouEditContactInfo
                                            ? false
                                            : true
                                    "
                                    autocomplete="disabled"
                                ></text-input>
                            </form-one-col-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="4">
                    <address-multiple-map-input
                        v-model="form.deliveryJob.pickupAddress.full_address"
                        style="height: 200px"
                        name="deliveryFullAddressMap"
                        :addresses="markers"
                        :draggable="false"
                    ></address-multiple-map-input>

                    <v-container>
                        <v-row justify="space-around">
                            <v-card width="400" elevation="0">
                                <v-card-text>
                                    <timeline
                                        :messages="timelineData"
                                    ></timeline>
                                </v-card-text>
                            </v-card>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>

            <confirm-delivery-modal
                v-if="form.deliveryJob"
                :value="deliveryDialog"
                :title="$t('texts.confirm_to_cancel_delivery')"
                :entity-id="parseInt(entityId)"
                :entity-count="deliveryProviderJobCount"
                :order-id="orderId"
                :approve-text="$t('actions.cancel-delivery')"
                approve-color="green"
                :processing="processing"
                @onApprove="executeCancelDelivery"
                @onCancel="cancelDeliveryDialog"
            ></confirm-delivery-modal>

            <portal v-if="form.deliveryJob" to="footer">
                <v-btn
                    color="grey"
                    large
                    outlined
                    style="margin-right: 0.75rem"
                    @click="backtolist"
                >
                    {{ $t('actions.back-to-list') }}
                </v-btn>

                <v-btn
                    v-if="form.canBeCancelled"
                    color="error"
                    large
                    outlined
                    style="margin-right: 0.75rem"
                    @click="cancelDelivery"
                >
                    <v-icon left> cancel </v-icon>
                    {{ $t('actions.cancel-delivery') }}
                </v-btn>

                <v-btn
                    v-if="
                        form.deliveryJob.canYouEditAddress ||
                        form.deliveryJob.canYouEditContactInfo
                    "
                    color="success"
                    :disabled="!isValid"
                    large
                    @click="updateDelivery"
                >
                    <v-icon left> autorenew </v-icon>
                    {{ $t('actions.update-delivery') }}
                </v-btn>

                <span
                    v-if="form.deliveryJob.canYouCreateProviderJob"
                    style="margin-right: 0.75rem"
                >
                    OR
                </span>

                <v-btn
                    v-if="form.deliveryJob.canYouCreateProviderJob"
                    color="success"
                    large
                    @click="requestNewDelivery"
                >
                    <v-icon left> content_copy </v-icon>
                    {{ $t('actions.request-new-delivery') }}
                </v-btn>
            </portal>
        </base-form>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import routeType from '@/router/routeType'
import authType from '@/store/type/authType'
import ApiClientType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import FormExtend from '@/components/mixins/FormExtend'
import AuthMixin from '@/components/mixins/AuthMixin'
import Notification from '@/services/Notification/Notification'
import configType from '@/store/type/configType'
import Timeline from './Timeline'
import dirtyType from '@/store/type/dirtyType'
import RestApiCollection from '@/api/RestApiCollection'
import MapperCollection from '@/services/mapper/MapperCollection'
import cloneDeep from 'lodash/cloneDeep'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMultipleMapInput from '@/components/form/Inputs/google/AddressMultipleMapInput'
import SelectApiClientType from '@/api/SelectApiClientType'
import ConfirmDeliveryModal from '@/components/confirm/ConfirmDeliveryModal'

export default {
    components: {
        ConfirmDeliveryModal,
        AddressMultipleMapInput,
        AddressAutocompleteInput,
        Timeline,
    },
    mixins: [FormExtend, AuthMixin],
    data() {
        return {
            api: ApiClientType.DELIVERIES,
            mapper: MapperType.DELIVERY_FORM,
            form: {},
            entity: 'labels.deliveries',
            editRoute: routeType.DELIVERY_EDIT,
            listRoute: routeType.DELIVERY_LIST,
            hasFormErrors: false,
            formErrors: [],
            isValid: false,
            selectApiClientType: SelectApiClientType,
            processing: false,
            timelineData: [],
        }
    },
    computed: {
        ...mapGetters({
            userId: authType.getters.USER_ID,
            currentLocale: configType.getters.CURRENT_LOCALE,
        }),
        minDate() {
            return this.$date().format('YYYY-MM-DD')
        },
        title: function () {
            return this.form.id
                ? this.form.id.toString() + '(' + this.orderId + ')'
                : this.$t('labels.deliveries')
        },
        ...mapState({
            deliveryDialog: (state) => state.dirty.deliveryDialog,
        }),
        getStatus() {
            return Object.keys(this.form)
        },
        orderId() {
            return this.form.id
                ? this.form.deliveryJob.orderIdentifiers[
                      Object.keys(this.form.deliveryJob.orderIdentifiers)[0]
                  ].toString()
                : null
        },
        formId() {
            return this.form.id
        },
        deliveryProviderJobCount() {
            return 1
            //return this.form.deliveryJob.activeDeliveryProviderJobCount
        },
        deliveryJobId() {
            return this.form.deliveryJob.id
        },
        markers() {
            return [
                {
                    address: this.form.deliveryJob.pickupAddress.full_address,
                    icon: 'restaurant',
                    lat: this.form.deliveryJob.pickupAddress.latitude,
                    lng: this.form.deliveryJob.pickupAddress.longitude,
                },
                {
                    address: this.form.deliveryJob.dropoffAddress.full_address,
                    icon: 'dropoff',
                    lat: this.form.deliveryJob.dropoffAddress.latitude,
                    lng: this.form.deliveryJob.dropoffAddress.longitude,
                },
            ]
        },
    },
    watch: {
        'form.deliveryJob.id': {
            handler(newVal) {
                this.getDeliveryJobTimeline(newVal)
            },
        },
    },
    methods: {
        baseFormValid(value) {
            this.isValid = value
        },
        ...mapMutations({
            setCancelDeliveryDialog:
                dirtyType.mutations.SET_CANCELDELIVERYDIALOG,
        }),
        editRedirectParams: (data) => ({ id: data.id }),
        cancelDeliveryDialog() {
            this.setCancelDeliveryDialog(false)
        },
        cancelDelivery() {
            this.setCancelDeliveryDialog(true)
        },
        countOrderProviderJobs(deliveryJobId) {
            RestApiCollection.get(this.api)
                .getDeliveryJob(deliveryJobId)
                .then((data) => {
                    this.deliverProviderJobCount =
                        data.activeDeliveryProviderJobCount
                })
                .catch(() => {
                    //this.$emit('failed', this.orderId)
                })
        },
        executeCancelDelivery(cancelordertype) {
            this.processing = true

            cancelordertype == 'orderjob'
                ? RestApiCollection.get(this.api)
                      .cancelDeliveryJob(this.deliveryJobId)
                      .then((data) => {
                          if (data.success === false) {
                              Notification.error(
                                  'Delivery ' +
                                      this.deliveryJobId +
                                      ' cancellation error',
                                  data.message
                              )
                          } else {
                              this.$emit('deleted', this.deliveryJobId)
                          }
                      })
                      .catch(() => {
                          this.$emit('failed', this.deliveryJobId)
                      })
                      .finally(() => {
                          this.setCancelDeliveryDialog(false)
                          this.processing = false
                          this.$refs.form.load()
                      })
                : RestApiCollection.get(this.api)
                      .cancelDeliveryProviderJob(this.entityId)
                      .then((data) => {
                          if (data.success === false) {
                              Notification.error(
                                  this.entityId + ' cancellation error',
                                  data.message
                              )
                          } else {
                              this.$emit('deleted', this.entityId)
                          }
                      })
                      .catch(() => {
                          this.$emit('failed', this.entityId)
                      })
                      .finally(() => {
                          this.setCancelDeliveryDialog(false)
                          this.processing = false
                          this.$refs.form.load()
                      })
        },
        updateDelivery() {
            this.$refs.form.busy = true
            const entity = MapperCollection.get(this.mapper).toEntity(
                this.$refs.form.value
            )

            let response
            this.$refs.form.resetFormErrors()
            this.$refs.form.hasFormErrors = false

            if (this.deliveryJobId) {
                response = RestApiCollection.get(this.api).updateDeliveryJob(
                    entity.deliveryJob
                )
            }

            response
                .then((response) => {
                    this.$refs.form.busy = false
                    const entity = MapperCollection.get(this.mapper).fromEntity(
                        response
                    )
                    this.$refs.form.savedFormdata = cloneDeep(entity)
                    this.$refs.form.setDirty(false)
                    if (this.$refs.form.entityId) {
                        this.updated(this.deliveryJobId, 'Updated')
                    }
                })
                .catch((reason) => {
                    if (reason.response.status === 404) {
                        this.$router.push({ name: 'not-found' })

                        return
                    }

                    if (reason.response.status !== 400) {
                        this.$refs.form.busy = false
                        return
                    }

                    if (
                        Object.prototype.hasOwnProperty.call(
                            reason.response.data,
                            'violations'
                        )
                    ) {
                        reason.response.data.violations.forEach((violation) => {
                            if (
                                this.$refs.form.$validator.fields.find({
                                    name: violation.propertyPath,
                                })
                            ) {
                                this.errors.add({
                                    field: violation.propertyPath,
                                    msg: violation.message,
                                })
                            } else {
                                this.$refs.form.hasFormErrors = true
                                this.$refs.form.formErrors.push(
                                    violation.propertyPath +
                                        ': ' +
                                        violation.message
                                )
                            }
                        })
                    } else {
                        this.$refs.form.hasFormErrors = true
                        this.$refs.form.formErrors.push(
                            Object.prototype.hasOwnProperty.call(
                                reason.response.data,
                                'message'
                            )
                                ? reason.response.data.message
                                : 'Server error'
                        )
                    }

                    Notification.formError()
                    this.$refs.form.busy = false
                    this.$emit('error')
                })
        },
        backtolist() {
            this.$router.back()
        },
        requestNewDelivery() {
            this.$router.push({
                name: routeType.DELIVERY_CREATE_PROVIDER_JOB,
                params: { id: this.entityId },
            })
        },
        getDeliveryProviderJobTimeline(id) {
            RestApiCollection.get(this.api)
                .getTimeline({
                    //deliveryJob: '/api/v1/delivery-jobs/326',
                    deliveryProviderJob: '/api/v1/delivery-provider-jobs/' + id,
                })
                .then((data) => {
                    this.timelineData = data['hydra:member']
                })
                .catch(() => {
                    //this.$emit('failed', this.orderId)
                })
        },
        getDeliveryJobTimeline(id) {
            RestApiCollection.get(this.api)
                .getTimeline({
                    deliveryJob: '/api/v1/delivery-jobs/' + id,
                })
                .then((data) => {
                    this.timelineData = data['hydra:member']
                })
                .catch(() => {
                    //this.$emit('failed', this.orderId)
                })
        },
    },
}
</script>
