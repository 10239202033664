var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-form',{ref:"form",attrs:{"api":_vm.api,"mapper":_vm.mapper,"entity-id":_vm.entityId,"title":_vm.title,"disabled":_vm.disabled,"disable-save-button":true,"disable-delete-button":true},on:{"valid":function($event){return _vm.baseFormValid($event)}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[(_vm.form.deliveryJob)?_c('v-row',{staticClass:"pt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[(_vm.form.error != null)?_c('v-alert',{attrs:{"color":"red","dense":"","text":"","type":"error"}},[_vm._v("Error: "+_vm._s(_vm.form.error)+" ")]):_vm._e(),_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"0"}},[_c('v-card-text',[_c('form-one-col-row',[[_c('date-picker-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:(
                                        'required|dateafter'
                                    ),expression:"\n                                        'required|dateafter'\n                                    ",modifiers:{"immediate":true}}],attrs:{"label":"orders.pickup_date","name":"pickupDate","error-messages":_vm.errors.collect('pickupDate'),"data-vv-as":_vm.$t('orders.pickup_date'),"editable":_vm.form.deliveryJob.canYouEditAddress,"min":_vm.minDate},model:{value:(_vm.form.deliveryJob.pickupTime),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob, "pickupTime", $$v)},expression:"form.deliveryJob.pickupTime"}})]],2),_c('form-one-col-row',[_c('address-autocomplete-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:('required'),expression:"'required'",modifiers:{"immediate":true}},{name:"tab-error",rawName:"v-tab-error:contractual",arg:"contractual"}],attrs:{"label":"orders.pickup_address","latitude":_vm.form.deliveryJob.pickupAddress.latitude,"longitude":_vm.form.deliveryJob.pickupAddress.longitude,"country":_vm.form.deliveryJob.pickupAddress.country,"town":_vm.form.deliveryJob.pickupAddress.city,"zip-code":_vm.form.deliveryJob.pickupAddress
                                        .postal_code,"street":_vm.form.deliveryJob.pickupAddress.street,"house-number":_vm.form.deliveryJob.pickupAddress
                                        .street_number,"name":"pickupAddress","data-vv-as":_vm.$t('orders.pickup_address'),"disabled":_vm.form.deliveryJob.canYouEditAddress
                                        ? false
                                        : true,"error-messages":_vm.errors.collect('pickupAddress')},on:{"update:latitude":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress, "latitude", $event)},"update:longitude":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress, "longitude", $event)},"update:country":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress, "country", $event)},"update:town":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress, "city", $event)},"update:zipCode":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress
                                        , "postal_code", $event)},"update:zip-code":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress
                                        , "postal_code", $event)},"update:street":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress, "street", $event)},"update:houseNumber":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress
                                        , "street_number", $event)},"update:house-number":function($event){return _vm.$set(_vm.form.deliveryJob.pickupAddress
                                        , "street_number", $event)}},model:{value:(
                                    _vm.form.deliveryJob.pickupAddress
                                        .full_address
                                ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.pickupAddress
                                        , "full_address", $$v)},expression:"\n                                    form.deliveryJob.pickupAddress\n                                        .full_address\n                                "}})],1),_c('form-two-col-row',{scopedSlots:_vm._u([{key:"col1",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:(
                                        'required|min:2|max:50|space'
                                    ),expression:"\n                                        'required|min:2|max:50|space'\n                                    "}],attrs:{"name":"pickupFirstName","label":_vm.$t('orders.first_name'),"disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled","error-messages":_vm.errors.collect('pickupFirstName')},model:{value:(
                                        _vm.form.deliveryJob.pickupAddress
                                            .contact_first_name
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.pickupAddress
                                            , "contact_first_name", $$v)},expression:"\n                                        form.deliveryJob.pickupAddress\n                                            .contact_first_name\n                                    "}})]},proxy:true},{key:"col2",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:(
                                        'required|min:2|max:50|space'
                                    ),expression:"\n                                        'required|min:2|max:50|space'\n                                    "}],attrs:{"label":_vm.$t('orders.last_name'),"name":"pickupLastName","disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled","error-messages":_vm.errors.collect('pickupLastName')},model:{value:(
                                        _vm.form.deliveryJob.pickupAddress
                                            .contact_last_name
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.pickupAddress
                                            , "contact_last_name", $$v)},expression:"\n                                        form.deliveryJob.pickupAddress\n                                            .contact_last_name\n                                    "}})]},proxy:true}],null,false,2332196010)}),_c('form-two-col-row',{scopedSlots:_vm._u([{key:"col1",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|phonenumber'),expression:"'required|phonenumber'"}],attrs:{"name":"pickupPhoneNumber","label":_vm.$t('orders.phone_number'),"disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"error-messages":_vm.errors.collect('pickupPhoneNumber'),"autocomplete":"disabled"},model:{value:(
                                        _vm.form.deliveryJob.pickupAddress
                                            .contact_phone
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.pickupAddress
                                            , "contact_phone", $$v)},expression:"\n                                        form.deliveryJob.pickupAddress\n                                            .contact_phone\n                                    "}})]},proxy:true},{key:"col2",fn:function(){return [_c('text-input',{attrs:{"label":_vm.$t('orders.pickup_address'),"name":"pickupCompany","disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled"},model:{value:(
                                        _vm.form.deliveryJob.pickupAddress
                                            .contact_company
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.pickupAddress
                                            , "contact_company", $$v)},expression:"\n                                        form.deliveryJob.pickupAddress\n                                            .contact_company\n                                    "}})]},proxy:true}],null,false,3787318023)}),_c('form-one-col-row',[_c('text-input',{attrs:{"name":"pickupComment","label":_vm.$t('orders.pickup_comment'),"disabled":_vm.form.deliveryJob.canYouEditContactInfo
                                        ? false
                                        : true,"autocomplete":"disabled"},model:{value:(_vm.form.deliveryJob.pickupComment),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob, "pickupComment", $$v)},expression:"form.deliveryJob.pickupComment"}})],1),_c('form-one-col-row',[_c('text-input',{attrs:{"name":"packageInfo","label":_vm.$t('orders.package_info'),"autocomplete":"disabled","disabled":_vm.form.deliveryJob.canYouEditContactInfo
                                        ? false
                                        : true},model:{value:(_vm.form.deliveryJob.packageInfo),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob, "packageInfo", $$v)},expression:"form.deliveryJob.packageInfo"}})],1)],1)],1),_c('div'),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('form-one-col-row',[_c('address-autocomplete-input',{directives:[{name:"validate",rawName:"v-validate.immediate",value:('required'),expression:"'required'",modifiers:{"immediate":true}},{name:"tab-error",rawName:"v-tab-error:contractual",arg:"contractual"}],attrs:{"label":_vm.$t('orders.dropoff_address'),"latitude":_vm.form.deliveryJob.dropoffAddress.latitude,"longitude":_vm.form.deliveryJob.dropoffAddress
                                        .longitude,"country":_vm.form.deliveryJob.dropoffAddress.country,"town":_vm.form.deliveryJob.dropoffAddress.city,"zip-code":_vm.form.deliveryJob.dropoffAddress
                                        .postal_code,"street":_vm.form.deliveryJob.dropoffAddress.street,"house-number":_vm.form.deliveryJob.dropoffAddress
                                        .street_number,"name":"dropoffAddress","error-messages":_vm.errors.collect('dropoffAddress'),"data-vv-as":_vm.$t('labels.dropoff_address'),"disabled":_vm.form.deliveryJob.canYouEditAddress
                                        ? false
                                        : true},on:{"update:latitude":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress, "latitude", $event)},"update:longitude":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress
                                        , "longitude", $event)},"update:country":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress, "country", $event)},"update:town":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress, "city", $event)},"update:zipCode":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress
                                        , "postal_code", $event)},"update:zip-code":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress
                                        , "postal_code", $event)},"update:street":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress, "street", $event)},"update:houseNumber":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress
                                        , "street_number", $event)},"update:house-number":function($event){return _vm.$set(_vm.form.deliveryJob.dropoffAddress
                                        , "street_number", $event)}},model:{value:(
                                    _vm.form.deliveryJob.dropoffAddress
                                        .full_address
                                ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.dropoffAddress
                                        , "full_address", $$v)},expression:"\n                                    form.deliveryJob.dropoffAddress\n                                        .full_address\n                                "}})],1),_c('form-two-col-row',{scopedSlots:_vm._u([{key:"col1",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:(
                                        'required|min:2|max:50|space'
                                    ),expression:"\n                                        'required|min:2|max:50|space'\n                                    "}],attrs:{"name":"dropoffFirstName","label":_vm.$t('orders.first_name'),"disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled","error-messages":_vm.errors.collect('dropoffFirstName')},model:{value:(
                                        _vm.form.deliveryJob.dropoffAddress
                                            .contact_first_name
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.dropoffAddress
                                            , "contact_first_name", $$v)},expression:"\n                                        form.deliveryJob.dropoffAddress\n                                            .contact_first_name\n                                    "}})]},proxy:true},{key:"col2",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:(
                                        'required|min:2|max:50|space'
                                    ),expression:"\n                                        'required|min:2|max:50|space'\n                                    "}],attrs:{"label":_vm.$t('orders.last_name'),"name":"dropoffLastName","disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled","error-messages":_vm.errors.collect('dropoffLastName')},model:{value:(
                                        _vm.form.deliveryJob.dropoffAddress
                                            .contact_last_name
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.dropoffAddress
                                            , "contact_last_name", $$v)},expression:"\n                                        form.deliveryJob.dropoffAddress\n                                            .contact_last_name\n                                    "}})]},proxy:true}],null,false,1968851114)}),_c('form-two-col-row',{scopedSlots:_vm._u([{key:"col1",fn:function(){return [_c('text-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|phonenumber'),expression:"'required|phonenumber'"}],attrs:{"name":"dropoffPhoneNumber","label":_vm.$t('orders.phone_number'),"disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled","error-messages":_vm.errors.collect('dropoffPhoneNumber')},model:{value:(
                                        _vm.form.deliveryJob.dropoffAddress
                                            .contact_phone
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.dropoffAddress
                                            , "contact_phone", $$v)},expression:"\n                                        form.deliveryJob.dropoffAddress\n                                            .contact_phone\n                                    "}})]},proxy:true},{key:"col2",fn:function(){return [_c('text-input',{attrs:{"label":_vm.$t('orders.company'),"name":"dropoffCompany","error-messages":_vm.errors.collect(
                                            'dropoffAddress[contact_company]'
                                        ),"disabled":_vm.form.deliveryJob
                                            .canYouEditContactInfo
                                            ? false
                                            : true,"autocomplete":"disabled"},model:{value:(
                                        _vm.form.deliveryJob.dropoffAddress
                                            .contact_company
                                    ),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.dropoffAddress
                                            , "contact_company", $$v)},expression:"\n                                        form.deliveryJob.dropoffAddress\n                                            .contact_company\n                                    "}})]},proxy:true}],null,false,3729791424)}),_c('form-one-col-row',[_c('text-input',{attrs:{"name":"dropoffComment","label":_vm.$t('orders.dropoff_comment'),"disabled":_vm.form.deliveryJob.canYouEditContactInfo
                                        ? false
                                        : true,"autocomplete":"disabled"},model:{value:(_vm.form.deliveryJob.dropOffComment),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob, "dropOffComment", $$v)},expression:"form.deliveryJob.dropOffComment"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('address-multiple-map-input',{staticStyle:{"height":"200px"},attrs:{"name":"deliveryFullAddressMap","addresses":_vm.markers,"draggable":false},model:{value:(_vm.form.deliveryJob.pickupAddress.full_address),callback:function ($$v) {_vm.$set(_vm.form.deliveryJob.pickupAddress, "full_address", $$v)},expression:"form.deliveryJob.pickupAddress.full_address"}}),_c('v-container',[_c('v-row',{attrs:{"justify":"space-around"}},[_c('v-card',{attrs:{"width":"400","elevation":"0"}},[_c('v-card-text',[_c('timeline',{attrs:{"messages":_vm.timelineData}})],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.form.deliveryJob)?_c('confirm-delivery-modal',{attrs:{"value":_vm.deliveryDialog,"title":_vm.$t('texts.confirm_to_cancel_delivery'),"entity-id":parseInt(_vm.entityId),"entity-count":_vm.deliveryProviderJobCount,"order-id":_vm.orderId,"approve-text":_vm.$t('actions.cancel-delivery'),"approve-color":"green","processing":_vm.processing},on:{"onApprove":_vm.executeCancelDelivery,"onCancel":_vm.cancelDeliveryDialog}}):_vm._e(),(_vm.form.deliveryJob)?_c('portal',{attrs:{"to":"footer"}},[_c('v-btn',{staticStyle:{"margin-right":"0.75rem"},attrs:{"color":"grey","large":"","outlined":""},on:{"click":_vm.backtolist}},[_vm._v(" "+_vm._s(_vm.$t('actions.back-to-list'))+" ")]),(_vm.form.canBeCancelled)?_c('v-btn',{staticStyle:{"margin-right":"0.75rem"},attrs:{"color":"error","large":"","outlined":""},on:{"click":_vm.cancelDelivery}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" cancel ")]),_vm._v(" "+_vm._s(_vm.$t('actions.cancel-delivery'))+" ")],1):_vm._e(),(
                    _vm.form.deliveryJob.canYouEditAddress ||
                    _vm.form.deliveryJob.canYouEditContactInfo
                )?_c('v-btn',{attrs:{"color":"success","disabled":!_vm.isValid,"large":""},on:{"click":_vm.updateDelivery}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" autorenew ")]),_vm._v(" "+_vm._s(_vm.$t('actions.update-delivery'))+" ")],1):_vm._e(),(_vm.form.deliveryJob.canYouCreateProviderJob)?_c('span',{staticStyle:{"margin-right":"0.75rem"}},[_vm._v(" OR ")]):_vm._e(),(_vm.form.deliveryJob.canYouCreateProviderJob)?_c('v-btn',{attrs:{"color":"success","large":""},on:{"click":_vm.requestNewDelivery}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" content_copy ")]),_vm._v(" "+_vm._s(_vm.$t('actions.request-new-delivery'))+" ")],1):_vm._e()],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }