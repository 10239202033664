<template>
    <div>
        <div class="font-weight-bold mb-2">Timeline</div>
        <v-timeline align-top dense class="pt-0 delivery-timeline">
            <v-timeline-item
                v-for="message in messages"
                :key="message.id"
                :color="statusColor(message.type)"
                class="pb-1"
                small
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                            <div class="font-weight-normal">
                                <div class="delivery_status_name">
                                    {{ $t('delivery_status.' + message.type) }}
                                </div>
                                <div class="delivery_status_time">
                                    {{
                                        $date(message.date).format(
                                            'HH:mm, DD MMM'
                                        )
                                    }}
                                </div>
                            </div>
                            <div>{{ message.message }}</div>
                        </div>
                    </template>
                    <span v-if="message.comment !== ''">{{
                        message.comment
                    }}</span>
                </v-tooltip>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>

<script>
export default {
    props: {
        messages: {
            type: Array,
        },
    },
    methods: {
        statusColor(statusName) {
            let color
            switch (statusName) {
                case 'delivered':
                    color = '#b6d7a8'
                    break
                case 'job_finished':
                    color = '#b6d7a8'
                    break
                case 'job_created':
                    color = '#e1e1e1'
                    break
                case 'order_placed':
                    color = '#e1e1e1'
                    break
                case 'cancelled':
                    color = '#ea9999'
                    break
                default:
                    color = '#d9d2e9'
            }
            return color
        },
    },
}
</script>
<style>
.delivery-timeline:before {
    height: 94%;
}
.v-application--is-ltr
    .delivery-timeline.v-timeline--dense:not(.v-timeline--reverse):before {
    left: calc(43px - 1px);
}
.delivery-timeline .v-timeline-item {
    margin-left: -2rem;
}
.delivery-timeline .v-timeline-item__divider {
    min-width: 41px;
}
</style>
